/* global Prism */

const Swiper = require('swiper/dist/js/swiper')

const initCarousel = (panelContent) => {
  const carousel = panelContent.querySelector('.c-project-panel__swiper')

  if (carousel !== null) {
    new Swiper(carousel, {
      a11y: true,
      containerModifierClass: 'c-project-panel__swiper--',
      navigation: {
        disabledClass: 'c-project-panel__swiper-button--disabled',
        nextEl: carousel.querySelector('.c-project-panel__swiper-button--next'),
        prevEl: carousel.querySelector('.c-project-panel__swiper-button--prev'),
      },
      pagination: {
        clickable: true,
        bulletActiveClass: 'c-project-panel__swiper-bullet--active',
        bulletClass: 'c-project-panel__swiper-bullet',
        el: carousel.querySelector('.c-project-panel__swiper-pagination'),
        hideOnClick: false,
        type: 'bullets',
      },
      setWrapperSize: true,
      slideClass: 'c-project-panel__swiper-slide',
      spaceBetween: 80,
      wrapperClass: 'c-project-panel__swiper-wrapper',
    })

    panelContent.classList.remove('js-project-panel--initialise-swiper')
    panelContent.classList.add('js-project-panel--swiper-initialised')
  }
}

const initProjectPanelToggle = () => {
  const projectContent = document.querySelector('.c-project__content')

  if (projectContent === null) {
    return
  }

  projectContent.addEventListener('click', projectPanelToggleClickHandler)
}

const highlightedClass = 'syntax-highlighted'
const prismInit = () => {
  window.Prism.hooks.add('after-highlight', (env) => {
    env.element.classList.add(highlightedClass)
  })
}

const initPrismSyntaxHighlighting = (container = document, force = false) => {
  const selector = [
    'pre code:not([class*="language-blocks"])',
    'code[class*="language-"]:not([class*="language-blocks"])',
  ].join(', ')
  const elements = container.querySelectorAll(selector)

  if (elements.length > 0) {
    for (const element of elements) {
      if (force || !element.classList.contains(highlightedClass)) {
        Prism.highlightElement(element)
      }
    }
  }
}

const initQuizChoiceSelection = () => {
  const quizContent = document.querySelector('.c-project-quiz__content')

  if (quizContent === null) {
    return
  }

  quizContent.addEventListener('click', quizInputClickHandler)
}

const isChallenge = (step) =>
  typeof step.challenge !== 'undefined' && step.challenge

const isHint = (panelContent) => {
  return (
    panelContent.classList.contains('js-project-panel--initialise-swiper') ||
    panelContent.classList.contains('js-project-panel--swiper-initialised')
  )
}

const isKnowledgeQuiz = (step) => typeof step?.knowledgeQuiz === 'string'

const isReflection = (step) => {
  return (
    typeof step.completion !== 'undefined' &&
    step.completion?.constructor?.name === 'Array' &&
    step.completion.includes('external') &&
    !step.quiz &&
    typeof step.knowledgeQuiz !== 'string'
  )
}

const projectHasBadge = (project) =>
  typeof project?.badgeTemplateId === 'string' &&
  typeof project?.providerId === 'string'

const projectPanelToggleClickHandler = (event) => {
  const clickedElement = event.target

  if (!clickedElement.classList.contains('js-project-panel__toggle')) {
    return
  }

  clickedElement.classList.toggle('c-project-panel__heading--has-close-icon')
  const panelContent = clickedElement.nextElementSibling

  panelContent.classList.toggle('u-hidden')

  if (!panelContent.classList.contains('u-hidden')) {
    initPrismSyntaxHighlighting(panelContent, true)
  }

  if (isHint(panelContent)) {
    initCarousel(panelContent)
  }

  trackHintIngredient(event, isHint(panelContent))
}

const removeScratchPreviewIframes = () => {
  const iframes = document.querySelectorAll('.scratch-preview iframe')
  for (let iframe of iframes) {
    iframe.parentNode.removeChild(iframe)
  }
}

const quizInputClickHandler = (event) => {
  const clickedElement = event.target
  const quizContent = document.querySelector('.c-project-quiz__content')
  const quizLabels = document.querySelectorAll('.c-project-quiz__label')
  const quizInputs = document.querySelectorAll('.c-project-quiz__input')
  const thankYouBox = document.createElement('div')

  if (!clickedElement.classList.contains('c-project-quiz__label')) {
    return
  }

  // Set active choice
  clickedElement.classList.add('c-project-quiz__label--active')
  clickedElement.nextElementSibling.classList.add(
    'c-project-quiz__input--active'
  )

  // Set other options to disabled
  for (let quizLabel of quizLabels) {
    if (!quizLabel.classList.contains('c-project-quiz__label--active')) {
      quizLabel.classList.add('c-project-quiz__label--disabled')
    }
  }
  for (let quizInput of quizInputs) {
    if (!quizInput.classList.contains('c-project-quiz__input--active')) {
      quizInput.disabled = true
      // Remove handler after choice
      quizContent.removeEventListener('click', quizInputClickHandler)
    }
  }

  // Add response box
  thankYouBox.classList.add('c-project-quiz__thank-you-box')
  thankYouBox.innerHTML = 'Thank you!'

  quizContent.appendChild(thankYouBox)
}

const stepInit = () => {
  initPrismSyntaxHighlighting()
  initQuizChoiceSelection()
  initProjectPanelToggle()
}

const stepPosition = (position) => (position ? parseInt(position, 10) : 0)

const stepSlideDirection = (currentStepPosition, nextStepPosition) =>
  currentStepPosition > nextStepPosition ? 'left' : 'right'

const trackHintIngredient = (event, hint) => {
  const title = event.target.textContent.trim()
  window.ga('send', {
    hitType: 'event',
    eventCategory: `Project ${hint ? 'hint' : 'ingredient'}`,
    eventAction: `Clicked ${hint ? 'hint' : `ingredient ${title}`}`,
    eventLabel: event.target.baseURI,
  })
}

export {
  prismInit,
  initPrismSyntaxHighlighting,
  isChallenge,
  isHint,
  isKnowledgeQuiz,
  isReflection,
  projectHasBadge,
  removeScratchPreviewIframes,
  stepInit,
  stepPosition,
  stepSlideDirection,
}
