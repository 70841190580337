import Loader from 'components/shared/Loader/Loader'
import NoMatch from 'components/shared/NoMatch/NoMatch'
import Loadable from 'react-loadable'

// The `/* webpackChunkName: 'CodeClubProjectListContainer' */` comment gives the
// bundle for that chunk a file name that includes `CodeClubProjectListContainer`.
// See https://webpack.js.org/api/module-methods/#import-

const AuthenticationContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ProjectPrintContainer' */ 'containers/AuthenticationContainer/AuthenticationContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const SilentRenewContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'SilentRenewContainerLoader' */ 'containers/AuthenticationContainer/SilentRenewContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const CodeClubLoader = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'CodeClub' */ 'components/CodeClub/CodeClub'),
  error: NoMatch,
  loading: Loader,
})

const CoderDojoLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'CoderDojo' */ 'components/CoderDojo/CoderDojo'
    ),
  error: NoMatch,
  loading: Loader,
})

const HomepageLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'Homepage' */ 'containers/HomepageContainer/HomepageContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const ExerciseContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'Homepage' */ 'containers/ExerciseContainer/ExerciseContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const PathwayContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'PathwayContainer' */ 'containers/PathwayContainer/PathwayContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const PathwayHeaderLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'Pathway' */ 'components/shared/PathwayHeader/PathwayHeader'
    ),
  error: NoMatch,
  loading: Loader,
})

const ProjectContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ProjectContainer' */ 'containers/ProjectContainer/ProjectContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const UserProjectListContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ProjectListContainer' */ 'containers/UserProjectListContainer/UserProjectListContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const CollectionContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'Collection' */ 'containers/CollectionContainer/CollectionContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const CollectionListLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'CollectionListLoader' */ 'containers/CollectionListContainer/CollectionListContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const ProjectListContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ProjectListContainer' */ 'containers/ProjectListContainer/ProjectListContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const ProjectPrintContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ProjectPrintContainer' */ 'containers/ProjectPrintContainer/ProjectPrintContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

const ProjectEditorContainerLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ProjectEditorContainer' */ 'containers/ProjectEditorContainer/ProjectEditorContainer'
    ),
  error: NoMatch,
  loading: Loader,
})

export {
  AuthenticationContainerLoader,
  CodeClubLoader,
  CoderDojoLoader,
  CollectionContainerLoader,
  CollectionListLoader,
  HomepageLoader,
  ExerciseContainerLoader,
  PathwayContainerLoader,
  PathwayHeaderLoader,
  UserProjectListContainerLoader,
  ProjectContainerLoader,
  ProjectEditorContainerLoader,
  ProjectListContainerLoader,
  ProjectPrintContainerLoader,
  SilentRenewContainerLoader,
}
