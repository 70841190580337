// Adapted from https://github.com/reactjs/redux/blob/734a2833e801b68cf64464d55ac98e8d75568004/examples/real-world/src/middleware/api.js
import config from 'helpers/config'
import { json as parseJson, status, updateFromJson } from 'helpers/fetch'
import { curry } from 'lodash'
import Raven from 'raven-js'
import { CALL_API } from 'redux/constants/ActionTypes'
import { isAbsoluteUrl, nestedIncluded, normalize } from 'redux/utils'

const API_ROOT = `${process.env.REACT_APP_API_URL}/api/v1`

const callApi = (
  method,
  endpoint,
  authToken = null,
  mapIds = false,
  data = {},
  options = {},
  normalizePath = 'data.attributes',
  includePath = 'included'
) => {
  const fullUrl = isAbsoluteUrl(endpoint) ? endpoint : `${API_ROOT}${endpoint}`
  const fetchOptions = { method, headers: {} }
  const getPostData = updateFromJson({
    data,
    parseResponse: options.parseResponse,
  })

  if (authToken) {
    fetchOptions.headers = {
      Authorization: authToken,
    }
  }

  let response = {}

  if (method === 'GET') {
    response = fetch(fullUrl, fetchOptions)
      .then(status)
      .then(parseJson)
      .then((json) => {
        const obj = Object.assign({}, normalize(json, mapIds, normalizePath))
        if (includePath in json) {
          obj.included = nestedIncluded(json[includePath])
        }
        return obj
      })
  } else {
    fetchOptions.headers['Content-Type'] = 'application/json'
    fetchOptions.body = JSON.stringify(data)
    response = fetch(fullUrl, fetchOptions)
      .then(status)
      .then(parseJson)
      .then(getPostData)
  }

  return response.catch((error) => {
    if (!error.status || error.status !== config.notFoundStatus) {
      Raven.captureMessage('API Error', {
        extra: {
          error: error,
        },
      })
    }

    if (!error.status || !error.response) {
      return Promise.reject({
        message: error.message,
        status: null,
        body: {},
      })
    }

    return error.response.json().then((x) => {
      return Promise.reject({
        message: error.message,
        status: error.status,
        body: Object.assign({}, normalize(x)),
      })
    })
  })
}

export const apiMiddleware = (next, action) => {
  const callAPI = action[CALL_API]

  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  const {
    types,
    method,
    endpoint,
    authToken,
    mapIds,
    data,
    options = {},
    normalizePath = 'data.attributes',
    actions,
    ...extras
  } = callAPI

  // This middleware expects to receive an array of 3 action types
  // e.g. `types: ['FETCH_PROJECT_REQUEST', 'RECEIVE_PROJECT', 'RECEIVE_ERROR']`
  //
  // It uses these to handle api requests, the first to get data,
  // the second to handle success responses and the last failure responses.
  //

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  const actionWith = (actionData) => {
    const finalAction = Object.assign({}, action, actionData)
    delete finalAction[CALL_API]
    return finalAction
  }
  const [requestType, successType, failureType] = types
  next(
    actionWith({
      type: requestType,
      ...callAPI,
    })
  )

  return callApi(
    method,
    endpoint,
    authToken,
    mapIds,
    data,
    options,
    normalizePath
  ).then(
    (response) => {
      next(
        actionWith({
          response,
          type: successType,
          extras,
          actions,
        })
      )
    },
    (error) => {
      next(
        actionWith({
          type: failureType,
          error: error || {
            message: 'Something went wrong',
            status: 500,
          },
          actions,
        })
      )
    }
  )
}

export default () => curry(apiMiddleware)
