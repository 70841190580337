import NoMatch from 'components/shared/NoMatch/NoMatch'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import React, { Component } from 'react'
import { translate } from 'react-i18next'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
    Raven.captureException(error, { extra: errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <NoMatch
          error={{
            status: 500,
            message: this.props.t('error-boundary.message'),
          }}
        />
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
}

export default translate('translations')(ErrorBoundary)
